<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-ranklist-tag"
    @click="handleClick"
  >
    <div class="bsc-cart-item-ranklist-tag__left">
      <span class="bsc-cart-item-ranklist-tag__text">
      {{ text }}
      </span>
      <span class="bsc-cart-item-ranklist-tag__suffix-text">
        {{ suffixText }}
      </span>
    </div>
    <sui_icon_more_right_12px
      class="bsc-cart-item-ranklist-tag__icon"
      v-if="isClick"
      color="rgba(213, 135, 0, 1)"
    />
  </div>
</template>
<script name="RankListTag" lang="ts" setup>
import { watch } from 'vue'
import sui_icon_more_right_12px from '../../../components/icon/sui_icon_more_right_12px.vue'
import { useInjectRegisterExpose } from '../../../hooks/useExpose'

interface IProps {
  /**
   * 是否显示
   */
  isShow: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 是否可点击
   */
  isClick: boolean
  /**
   * 标签文案
   */
  text: string
  /**
   * 附属文案
   */
  suffixText: string
  /**
   * 标签类型
   */
  type: string
  /**
   * 背景颜色
   */
  bgColor: string
  /**
   * 文字颜色
   */
  textColor: string
  /**
   * 最大宽度，需要单位
   */
  maxWidth?: string
  /**
   * 原数据
   */
  data: any
}

const props = withDefaults(defineProps<IProps>(), {
  text: '',
  suffixText: '',
  bgColor: '',
  textColor: '',
  isShow: false,
  isShowMask: false,
  isClick: false,
  type: '',
  maxWidth: '',
})

const registerExpose = useInjectRegisterExpose()
const emit = defineEmits(['onClick', 'onReport', 'onExpose'])

function handleClick() {
  emit('onReport', props.data)
  if (props.isClick) {
    emit('onClick', props.data)
  }
}

function handleExpose() {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('onExpose', props.data)
      }
      registerExpose('RankListTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()

</script>
<style lang="less">
.bsc-cart-item-ranklist-tag {
  background-color: #FFFBEB;
  border-radius: 6/37.5rem 2/37.5rem 2/37.5rem 1/37.5rem;
  padding-right: 4/37.5rem;
  color: #D58700;
  font-size: 10px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  max-width: 100%;
  overflow: hidden;
  height: 14/37.5rem;
  &__left {
    flex-shrink: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__text {
    display: inline-block;
    border-radius: 6/37.5rem 1/37.5rem;
    padding-left: 4/37.5rem;
    padding-right: 2/37.5rem;
    background: #FFE8AE;
    font-weight: 700;
    color: #C77210;
    margin-right: 2/37.5rem;
    line-height: 14/37.5rem;
  }
  &__suffix-text {
    margin-right: 2/37.5rem;
  }
  &__icon {
    flex-shrink: 0;
  }
}
</style>
