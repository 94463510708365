<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-goods-almost-entry__wrap"
    @click="handleClick"
  >
    <div class="bsc-cart-item-goods-almost-entry__img">
      <img
        class="lazyload"
        :data-src="imgUrl"
        :src="constants.LAZY_IMG"
      />
    </div>
    <span
      class="bsc-cart-item-goods-almost-entry__text"
      v-html="entryText"
    >
    </span>
    <template v-if="buttonText">
      <span class="bsc-cart-item-goods-almost-entry__button">{{ buttonText }}</span>
    </template>
    <template v-else>
      <sui_icon_more_right_12px
        class="bsc-cart-item-goods-almost-entry__icon"
      />
    </template>
  </div>
</template>

<script name="ChangeMall" setup lang="ts">
import { watch } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import { useInjectConstants } from'../../hooks/useConstant'
import sui_icon_more_right_12px from '../../components/icon/sui_icon_more_right_12px.vue'
import type { GoodsAlmostEntry } from '../../../../types/mobile/index.ts'
import type { Ref } from 'vue'

/**
 * 商品行低库存入口
 */

// hooks
const constants = useInjectConstants<Ref<GoodsAlmostEntry.Constant>>()
const registerExpose = useInjectRegisterExpose()

// events
const emit = defineEmits(['onClick', 'onReport', 'onExpose'])

interface GoodsAlmostEntryProps {
  /**
   * 是否展示
   */
  isShow: boolean
  /**
   * 是否能点击
   */
  isClick: boolean
  /**
   * 入口图片 URL
   */
  imgUrl: string
  /**
   * 入口文案
   */
  entryText: string
  /**
   * 按钮文案
   * 有值则渲染 button 组件，否则渲染 > icon
   */
  buttonText: string
  /**
   * 入口原数据
   */
  info: any
}

// props
const props = withDefaults(defineProps<GoodsAlmostEntryProps>(), {
  isShow: false,
  isClick: true,
  imgUrl: '',
  entryText: '',
  buttonText: '',
  info: null,
})

// events
const handleClick = () => {
  emit('onReport')
  if (props.isClick) {
    emit('onClick', props.info)
  }
}
const handleExpose = () => {
  watch(
    () => props.isShow,
    n => {
      if (n) {
        const callback = () => {
          emit('onExpose', props.info)
        }
        registerExpose('GoodsAlmostEntry', callback)
      }
    },
    {
      immediate: true,
    },
  )
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-goods-almost-entry {
  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4/37.5rem;
    background-color: #F6F6F6;
    cursor: pointer;
  }
  &__img {
    display: flex;
    width: 28/37.5rem;
    height: 28/37.5rem;
    margin-right: 4/37.5rem;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__text {
    min-width: 0;
    flex: 1;
    font-size: 12px;
    line-height: 14/37.5rem;
    display: -webkit-box;
    -webkit-line-clamp:2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
  &__button {
    padding: 4px 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    border: 1px solid #000;
    border-radius: 22px;
  }
  &__icon {
    flex-shrink: 0;
    margin-left: 4/37.5rem;
  }
}
</style>
