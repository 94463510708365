import type { RankListTag } from '../../../../../types/mobile/index'
import { getProps as getPromoProps } from '../promotion-tag/helper'
import { getProps as getSharePromotionProps } from '../../share-promotion-tag/helper'

export function getProps({ data }):RankListTag.Props {
  let apb = data.value.aggregateProductBusiness || {}
  if(!apb?.productTags) apb = data.value // for share select api
  let info = apb.productTags?.find(item => item.type === 'rankList')
  const promoTagProps = getPromoProps({ data })
  const sharePromotionProps = getSharePromotionProps({ data })
  return {
    isShow: (!promoTagProps?.isShow && !sharePromotionProps?.isShow) && !!info,
    isShowMask: false,
    isClick: info?.data?.rankList?.jumpLink ? true : false,
    text: info?.view?.text || '',
    suffixText: info?.view.suffixText || '',
    type: info?.type,
    bgColor: '',
    textColor: '',
    maxWidth: '',
    data: info,
  }
}