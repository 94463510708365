<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-discount-limit-tag"
    :style="style"
    @click="handleClick"
  >
    <RestorePrice
      v-if="restorePriceInfo"
      :restore-price-info="restorePriceInfo"
      @countDownEnd="onCountDownEnd"
    />
    <template v-else>
      <div class="bsc-cart-item-discount-limit-tag__text">
        {{ text }}
      </div>
      <ClientOnly>
        <template v-if="showCountdown">
          <div class="bsc-cart-item-discount-limit-tag__line"></div>
          <template v-if="timestampText">
            <p
              class="bsc-cart-item-discount-limit-tag__count-down-text"
              :style="{ color: textColor }"
              v-html="timestampText"
            ></p>
          </template>
          <CartCountdown
            v-else-if="timestamp"
            class="bsc-cart-item-discount-limit-tag__count-down"
            font-size="10px"
            :timestamp="timestamp"
            :font-color="textColor"
            :colon-color="textColor"
            font-bg-color="#fff"
          />
        </template>
      </ClientOnly>
    </template>
    <sui_icon_info_12px_2
      v-if="isClick"
      class="bsc-cart-item-discount-limit-tag__icon"
    />
  </div>
</template>

<script name="DiscountLimitTag" lang="ts" setup>
import { computed, watch } from 'vue'
import ClientOnly from 'vue-client-only'
import sui_icon_info_12px_2 from '../../components/icon/sui_icon_info_12px_2.vue'
import CartCountdown from '../../components/cart-countdown.vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import RestorePrice from '../estimated-price/restore-price.vue'

interface IProps {
  /**
   * 是否显示
   */
  isShow: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 是否可点击
   */
  isClick: boolean
  /**
   * 标签文案
   */
  text: string
  /**
   * 倒计时时间戳
   */
  timestamp?: number
  /**
   * 倒计时时间戳
   */
  timestampText?: string
  /**
   * 优惠明细原数据
   */
  estimatedPricePopUp: any
  /**
   * 背景颜色
   */
  bgColor: string
  /**
   * 文字颜色
   */
  textColor: string
  /**
   * 线条颜色
   */
  lineColor: string
  /**
   * 最大宽度，需要单位
   * 达到指定宽度时，溢出隐藏
   */
  maxWidth?: string
  /**
   * 恢复价格信息
   */
  restorePriceInfo?: {
    restoreTip: string
    restorePrice: {
      amount: string
      amountWithSymbol: string
      usdAmount: string
      usdAmountWithSymbol: string
      priceShowStyle: string
    },
    restoreEndTimeText: string
    restoreEndTimeStamp: string
    nowDateTimeStamp: string
  }
}

// props
const props = withDefaults(defineProps<IProps>(), {
  isShow: false,
  isShowMask: false,
  isClick: false,
  text: '',
  // timestamp: null,
  // estimatedPricePopUp: null,
  textColor: '#FA6338',
  lineColor: '#FDB9A6',
  bgColor: 'linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%)',
  maxWidth: '100%',
})

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['onExpose', 'onClick', 'onCountDownEnd'])

const style = computed(() => ({
  '--text-color': props.textColor,
  '--line-color': props.lineColor,
  '--bg-color': props.bgColor,
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  maxWidth: props.maxWidth,
}))

const showCountdown = computed(() => {
  if (!(props.timestampText || props.timestamp)) return false

  return true
})

const handleClick = () => {
  if (props.isClick) {
    emit('onClick')
  }
}

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('onExpose')
      }
      registerExpose('DiscountLimitTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()

const onCountDownEnd = () => {
  emit('onCountDownEnd')
}
</script>

<style lang="less">
.bsc-cart-item-discount-limit-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  height: 16px;
  font-size: 10px;
  line-height: 10px;
  color: #FA6338;
  color: var(--text-color);
  background: linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%);
  background: var(--bg-color);
  opacity: var(--mask-opacity);

  &__text {
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__line {
    flex-shrink: 0;
    margin: 0 6px;
    height: 10px;
    border-left: 1px solid #FDB9A6;
    border-left-color: #FDB9A6;
    border-left-color: var(--line-color);
  }

  &__count-down {
    flex-shrink: 0;
  }

  &__icon {
    flex-shrink: 0;
  }
  &__count-down-text {
    font-weight: 500;
    flex-shrink: 10000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
