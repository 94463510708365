<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-estimated-price"
    :style="style"
    @click="handleClick"
  >
    <RestorePrice
      v-if="restorePriceInfo"
      :restore-price-info="restorePriceInfo"
      @countDownEnd="onCountDownEnd"
    />
    <template v-else>
      <div
        v-if="price"
        class="bsc-cart-item-estimated-price__price"
      >
        <span
          v-html="price"
        ></span>
      </div>
      <div class="bsc-cart-item-estimated-price__text">
        <div
          v-if="price"
          class="bsc-cart-item-estimated-price__line">
        </div>{{ text }}
      </div>
      <ClientOnly>
        <template v-if="showCountdown">
          <div class="bsc-cart-item-estimated-price__line"></div>
          <template v-if="timestampText">
            <p
              class="bsc-cart-item-estimated-price__count-down-text"
              :style="{ color: textColor }"
              v-html="timestampText"
            ></p>
          </template>
          <CartCountdown
            v-else-if="timestamp"
            class="bsc-cart-item-estimated-price__count-down"
            font-size="10px"
            :timestamp="timestamp"
            :font-color="textColor"
            :colon-color="textColor"
            font-bg-color="#fff"
          />
        </template>
      </ClientOnly>
    </template>
    <sui_icon_info_12px_2
      v-if="isClick"
      class="bsc-cart-item-estimated-price__icon"
    />
  </div>
</template>

<script name="EstimatedPrice" lang="ts" setup>
import { computed, watch, ref } from 'vue'
import ClientOnly from 'vue-client-only'
import sui_icon_info_12px_2 from '../../components/icon/sui_icon_info_12px_2.vue'
import CartCountdown from '../../components/cart-countdown.vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import RestorePrice from './restore-price.vue'

interface IProps {
  /**
   * 是否显示
   */
  isShow: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 是否可点击
   */
  isClick: boolean
  /**
   * 标签文案
   */
  text: string
  /**
   * 到手价 html
   */
  price: string
  /**
   * 倒计时时间戳
   */
  timestamp?: number
  /**
   * 倒计时时间戳
   */
  timestampText?: string
  /**
   * 是否隐藏倒计时
   */
  hideCountdown: boolean
  /**
   * 优惠明细原数据
   */
  estimatedPricePopUp: any
  /**
   * 背景颜色
   */
  bgColor: string
  /**
   * 文字颜色
   */
  textColor: string
  /**
   * 线条颜色
   */
  lineColor: string
  /**
   * 最大宽度，需要单位
   * 达到指定宽度时，溢出隐藏
   */
  maxWidth?: string
  /**
   * 是否勾选
   */
  isChecked?: boolean
  /**
   * 到手价
   */
  estimatedPrice?: any
  /**
   * 恢复价格信息
   */
  restorePriceInfo?: {
    restoreTip: string
    restorePrice: {
      amount: string
      amountWithSymbol: string
      usdAmount: string
      usdAmountWithSymbol: string
      priceShowStyle: string
    },
    restoreEndTimeText: string
    restoreEndTimeStamp: string
    nowDateTimeStamp: string
  }
}

// props
const props = withDefaults(defineProps<IProps>(), {
  isShow: false,
  isShowMask: false,
  isClick: false,
  text: '',
  // timestamp: null,
  hideCountdown: false,
  // estimatedPricePopUp: null,
  textColor: '#FA6338',
  lineColor: '#FDB9A6',
  bgColor: 'linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%)',
  maxWidth: '100%',
  isChecked: false,
  estimatedPrice: null,
})

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['onExpose', 'onClick', 'onExposeChange', 'onCountDownEnd'])

const style = computed(() => ({
  '--text-color': props.textColor,
  '--line-color': props.lineColor,
  '--bg-color': props.bgColor,
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  maxWidth: props.maxWidth,
}))
const isReport = ref(false)
const isChange = computed(() => ({
  isShow: props.isShow,
  isChecked: props.isChecked,
  estimatedPrice: props.estimatedPrice,
}))

const showCountdown = computed(() => {
  if (props.hideCountdown || !(props.timestampText || props.timestamp)) return false

  return true
})

const handleClick = () => {
  if (props.isClick) {
    emit('onClick')
  }
}

const handleChangeExpose = () => {
  watch(() => isChange.value, (n, o) => {
    if (n.isChecked !== o?.isChecked) {
      isReport.value = false
    }
    if (n.isShow) {
      const callback = () => {
        if (n.isShow && !isReport.value) {
          emit('onExposeChange', { n, o })
          isReport.value = true
        }
      }
      registerExpose('EstimatedPriceChange', callback, false)
    }
  }, {
    immediate: true,
  })
}

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('onExpose')
      }
      registerExpose('EstimatedPrice', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
handleChangeExpose()

const onCountDownEnd = () => {
  emit('onCountDownEnd')
}
</script>

<style lang="less">
.bsc-cart-item-estimated-price {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  height: 18px;
  font-size: 10px;
  line-height: 10px;
  color: #FA6338;
  color: var(--text-color);
  background: linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%);
  background: var(--bg-color);
  opacity: var(--mask-opacity);
  overflow: hidden;

  &__price {
    display: inline-flex;
    align-items: center;
    font-weight: 700;

    & > span {

      max-width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      white-space: normal;
      word-break: break-all;

      /* stylelint-disable-next-line selector-max-type  */
      em {
        font-size: 15px;
        line-height: 1;
      }
    }
  }

  &__text {
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    > .bsc-cart-item-estimated-price__line {
      transform: translateY(1px);
    }
  }

  &__line {
    flex-shrink: 0;
    display: inline-block;
    margin: 0 6px;
    height: 10px;
    border-left: 1px solid #FDB9A6;
    border-left-color: #FDB9A6;
    border-left-color: var(--line-color);
  }

  &__count-down {
    flex-shrink: 0;
  }

  &__icon {
    flex-shrink: 0;
  }
  &__count-down-text {
    font-weight: 500;
    flex-shrink: 10000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
