import { hexToRgb } from '@shein/common-function'
import { getTimestampFormProductTags } from '../../utils/common'

import { DiscountLimitTag } from '../../../../types/mobile/index.ts'

/**
 * DiscountLimitTag 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }): DiscountLimitTag.Props {
  const apb = data.value.aggregateProductBusiness || {}
  const { estimatedData = {} } = apb

  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1'
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  const isShowMask = (isGiftItem && isNotChecked) || isInvalidItem || isNewcomerItem

  const timestampText = estimatedData.estimatedPriceEndTimeText || ''
  const timestamp = +(estimatedData.estimatedPriceEndTimeStamp ?? 0)

  const estimatedPricePopUp = apb.estimatedPricePopUp && JSON.parse(JSON.stringify(apb.estimatedPricePopUp))
  if (estimatedPricePopUp?.subInfo?.items) estimatedPricePopUp.subInfo.items = estimatedPricePopUp?.subInfo.items.filter(item => item.benefitType !== 'estimatedAmount')
  let isShowEndTimestamp = !!(timestampText || timestamp)
  // 为时间戳则表明是走老逻辑, 需要判断展示优先级
  if (isShowEndTimestamp && (timestamp && !timestampText)) {
    isShowEndTimestamp = !getTimestampFormProductTags(apb.productTags)
  }

  const text = estimatedData.estimatedTip
  let isShow = !estimatedData.style && !!apb.discountSubscript && isShowEndTimestamp && !!text
  const isClick = !isShowMask && !!estimatedPricePopUp
  if (['2', '3'].includes(apb.discountSubscript?.style)) {
    // 合规站点只会展示 DiscountNormalTag（产品要求折扣标签不显示倒计时）
    isShow = false
  }

  const textColor = estimatedData.textColor
  const lineColor = textColor && hexToRgb({ hex: textColor, opa: 0.4 })

  return {
    isShow, // 没有到手价标签，且存在折扣倒计时时，才会展示该组件
    isShowMask,
    isClick,
    text,
    timestamp,
    timestampText,
    estimatedPricePopUp,
    textColor,
    lineColor,
    bgColor: 'linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%)',
    maxWidth: '100%',
    restorePriceInfo: estimatedData.restorePriceInfo,
  }
}
